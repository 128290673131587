<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets" style="margin-top: 5px"></i>
      <span style="margin-top: 5px">Memberships</span>
    </el-card>
    <div class="table-container">
      <el-table
        ref="table"
        style="width: 100%"
        :data="list"
        v-loading="listLoading"
        border
      >
      <el-table-column label="Brand Name" align="center">
          <template slot-scope="scope">{{ scope.row.brandName.chs }}</template>
      </el-table-column>
      <el-table-column label="Operations" align="center">
        <template slot-scope="scope"><el-button size="mini" type="primary" @click="edit(scope.row.id)">Details/Edit</el-button></template>
      </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { fetchList } from '@/api/membership';

export default {
  name: 'index',
  data() {
    return {
      list: null,
      listLoading: true,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      fetchList().then((response) => {
        this.listLoading = false;
        this.list = response.data;
      });
    },
    edit(id) {
      this.$router.push({
        path: '/membership/updateMembership',
        query: { id },
      });
    },
  },
};
</script>
